import React from 'react'
import { config, useSpring } from 'react-spring'
import Layout from '../components/layout'
import { AnimatedBox } from '../elements'
import SEO from '../components/SEO'

const About = () => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  return (
    <Layout>
      <SEO title="About | Lantern of the Soul" desc="Lantern of the Soul Photography" />
      <AnimatedBox style={pageAnimation} py={[6, 6, 6, 8]} px={[6, 6, 8, 6, 8, 13]}>
        <h1>Lantern of the Soul Photography</h1>
        <sup> - Professional Photography since 2009</sup>
        <p><b>
          With the bombardment of so many images these days, it is rare to be left with photos that will leave you thrilled and in awe. My name is Danielle Bessemer and I truly believe that photos are a way of reliving a special moment in time. My passion for photography gives me a desire to create images that reveal the raw emotions, candid moments, and an artistic flare that will leave you not only with beautiful photos, but memories that will tell you a story and bookmark a chapter of time. My goal is to not only record the story on your special day, but to capture realistic moments instead of posing, find details that would normally be overlooked and use natural lighting that gives it a genuine feeling. Overall, you will be left with beautiful photos that you can enjoy looking at. 
        </b></p>
        <p>
          Please feel free to send us an email. <br />We're available for hire!{' '}
          <a href="mailto:inquiry@lanternofthesoul.com">inquiry@lanternofthesoul.com</a>.
        </p>
      </AnimatedBox>
    </Layout>
  )
}

export default About
